import ConfirmationModal from "component/confimation-modal";
import ConfirmDeleteModalHeader from "component/confirm-delete";
import Loader from "component/loader";

const { createContext, useContext, useState, useEffect } = require("react");
const { default: Api } = require("utils/api");

const initTeamsContextPropsState = {
  teams: [],
  setTeams: () => {},
  openModal: false,
  setOpenModal: () => {},
  selectedTeam: undefined,
  setSelectedTeam: () => {},
  showConfirmDelete: false,
  setShowConfirmDelete: () => {},
};

const TeamsContext = createContext(initTeamsContextPropsState);

const useTeam = () => {
  return useContext(TeamsContext);
};

const TeamsProvider = ({ children }) => {
  const [loader, setLoader] = useState(true);
  const [teams, setTeams] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const getTeams = async () => {
    setLoader(true);
    const { response } = await Api("/teams", "get");
    console.log(response, "response");
    if (response) setTeams(response.data);
    setLoader(false);
  };

  const onLoad = () => {
    getTeams();
  };
  useEffect(onLoad, []);

  const closeDeleteModal = () => {
    setShowConfirmDelete(false);
    setSelectedTeam();
  };

  const DeleteTeam = async () => {
    const { response } = await Api("/teams?_id=" + selectedTeam._id, "delete", {
      notify: true,
    });
    if (response) {
      const index = teams.findIndex((item) => item._id == selectedTeam._id);
      teams.splice(index, 1);
      setTeams([...teams]);
      setShowConfirmDelete(false);
    }
    setSelectedTeam();
  };
  return (
    <TeamsContext.Provider
      value={{
        teams,
        setTeams,
        openModal,
        setOpenModal,
        selectedTeam,
        setSelectedTeam,
        showConfirmDelete,
        setShowConfirmDelete,
      }}
    >
      {loader ? <Loader /> : children}
      <ConfirmationModal
        open={showConfirmDelete}
        onClose={closeDeleteModal}
        onConfirm={DeleteTeam}
      >
        <ConfirmDeleteModalHeader onClose={closeDeleteModal}>
          <p className="p-4">
            Are you sure you want to delete <b>{selectedTeam?.name}</b>
          </p>
        </ConfirmDeleteModalHeader>
      </ConfirmationModal>
    </TeamsContext.Provider>
  );
};

export { TeamsProvider, useTeam };
