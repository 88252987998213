import React, { useState } from 'react'
import Modal from './modal'
import Button from './Buttons/login-button'
import SecondaryBtn from './Buttons/secondary-btn'

function ConfirmationModal({ open, onConfirm, onClose, children }) {
  const [processing, setProcessing] = useState(false)

  const handleConfirm = () => {
    setProcessing(true)
    onConfirm && onConfirm().then(() => setProcessing(false))
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className='w-96 bg-white'>
        {children}
        <div className='flex items-center p-4 border-t border-gray-200 rounded-b '>
          <Button processing={processing} onClick={handleConfirm}>
            Confirm
          </Button>
          <SecondaryBtn onClick={onClose} label={'Cancel'} />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
