import React from 'react'
import CloseIcon from 'icon/close'
import WarningIcon from 'icon/warning'

function ConfirmDeleteModalHeader({ onClose, heading }) {
  return (
    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
      <h3 className="text-xl font-semibold text-gray-900 flex items-center">
        <WarningIcon size={30} />{' '}
        <span className="ml-2">{heading || 'Confirm Delete?'}</span>
      </h3>
      <button
        onClick={onClose}
        type="button"
        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
      >
        <CloseIcon />
        <span className="sr-only">Close modal</span>
      </button>
    </div>
  )
}

export default ConfirmDeleteModalHeader
