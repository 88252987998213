import React from 'react'

function NewsFeedIcon({ className }) {
  return (
    <svg
      class={`h-6 w-6 text-inherit ${className}`}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      stroke-width='2'
      stroke='currentColor'
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      {' '}
      <path stroke='none' d='M0 0h24v24H0z' />{' '}
      <path d='M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11' />{' '}
      <line x1='8' y1='8' x2='12' y2='8' />{' '}
      <line x1='8' y1='12' x2='12' y2='12' />{' '}
      <line x1='8' y1='16' x2='12' y2='16' />
    </svg>
  )
}

export default NewsFeedIcon
