import InputFieldOutlined from 'component/Inputs/dashboard-input'
import Select from 'component/Select/select'
import Loader from 'component/loader'
import SearchIcon from 'icon/search'
import SortIcon from 'icon/sort'
import React, { useEffect, useState } from 'react'

function Table({
  columns = [],
  rows = [],
  loader,
  isSearchable,
  isSortable,
  isDeepSearch,
  fixedHeader,
  height,
}) {
  const [filteredRows, setFilteredRows] = useState([])
  const [sortOrder, setSortOrder] = useState({})

  const deepSearch = (ReactObject, query) => {
    if (typeof ReactObject.props.children == 'string') {
      const value = ReactObject.props.children
      return value.toLowerCase().includes(query.toLowerCase())
    }
    if (!Array.isArray(ReactObject.props.children))
      return deepSearch(ReactObject.props.children, query)

    return false
  }

  const handleSearch = (e) => {
    const { value: query } = e.target
    if (!query) {
      return setFilteredRows(rows)
    }
    const filtered = rows.filter((row) => {
      return Object.values(row).some((value) => {
        if (typeof value == 'string')
          return value.toLowerCase().includes(query.toLowerCase())

        if (isDeepSearch) return deepSearch(value, query)
        return false
      })
    })
    setFilteredRows([...filtered])
  }

  const handleSort = (accessor, order) => {
    const newSortOrder = order === 'asc' ? 'desc' : 'asc'
    setSortOrder({ ...sortOrder, [accessor]: newSortOrder })

    let sortedRows = [...rows].sort((a, b) => {
      a = a[accessor]
      b = b[accessor] || ''
      if (order === 'asc') return a > b ? 1 : -1
      else return a > b ? -1 : 1
    })
    setFilteredRows(sortedRows)
  }

  useEffect(() => {
    setFilteredRows(rows)
  }, [rows])

  return (
    <div
      class='relative overflow-x-auto shadow-md sm:rounded-lg'
      style={{ maxHeight: height }}
    >
      {isSearchable && (
        <div className='bg-white flex justify-between py-3 px-6'>
          <div className='w-1/3'>
            <InputFieldOutlined
              placeholder={'Search'}
              onChange={handleSearch}
              StartIcon={<SearchIcon />}
            />
          </div>
        </div>
      )}
      <table class='w-full text-sm text-left rtl:text-right text-gray-500 '>
        <thead
          class={`text-xs text-gray-700 uppercase bg-gray-50 ${
            fixedHeader ? 'sticky top-0' : ''
          }`}
        >
          <tr>
            {columns.map((header) => (
              <th
                scope='col'
                class='px-6 py-3 group/header'
                style={{ width: header.width }}
              >
                <div className='flex items-center'>
                  {header.name}

                  {isSortable && (
                    <SortIcon
                      onClick={() =>
                        handleSort(
                          header.accessor,
                          sortOrder[header.accessor] || 'asc'
                        )
                      }
                      size={12}
                      className={
                        'ml-auto text-gray-600 hidden group-hover/header:block'
                      }
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {loader ? (
            <tr className='bg-white'>
              <td colSpan={columns.length} className='py-6'>
                <Loader />
              </td>
            </tr>
          ) : (
            filteredRows.map((row) => (
              <tr class='bg-white border-b '>
                {columns.map((col, idx) =>
                  idx === 0 ? (
                    <th
                      scope='row'
                      class='px-6 py-4 font-medium text-gray-900 whitespace-nowrap '
                    >
                      {row[col.accessor]}
                    </th>
                  ) : (
                    <td class='px-6 py-4'>{row[col.accessor]}</td>
                  )
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Table
