import React from 'react'

function SearchIcon({ size, ...props }) {
  return (
    <svg
      width={size || '20px'}
      height={size || '20px'}
      fill='none'
      viewBox='0 0 20 20'
      {...props}
    >
      <path
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
      />
    </svg>
  )
}

export default SearchIcon
