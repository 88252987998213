import { useAgency } from 'context/agency'
import React from 'react'

function Card({ children, className }) {
  const { agency } = useAgency()
  const card_bg = agency.location_config?.card_bg_color
  const card_text = agency.location_config?.card_text_color

  return (
    <div
      style={{ backgroundColor: card_bg, color: card_text }}
      class={`${card_bg ? '' : 'bg-white'} ${
        card_text ? '' : 'text-black'
      } border rounded-lg overflow-auto border-gray-200 p-6 ${className}`}
    >
      {children}
    </div>
  )
}
export default Card
