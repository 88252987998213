import React from "react";
import { TeamsProvider } from "./teams-context";
import Teams from "./teams";

function TeamsModule() {
  return (
    <TeamsProvider>
      <Teams />
    </TeamsProvider>
  );
}

export default TeamsModule;
