import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'
import { useLocationInfo } from 'context/location'
import { elements } from 'chart.js'
import TeamsModule from 'screens/admin/teams'
import Teams from 'screens/admin/teams/teams'

// DIRECTORIES
const Business = ComponentLoader(
  lazy(() => import('screens/admin/Directories/directories'))
)
const UpdateBusiness = ComponentLoader(
  lazy(() => import('screens/admin/Directories/update'))
)

// CONSUMERS
const Consumers = ComponentLoader(
  lazy(() => import('screens/admin/Consumers/directories'))
)
const CustomerDetails = ComponentLoader(
  lazy(() => import('screens/admin/GeneralInfo/customer-details'))
)

// PAGES
const Pages = ComponentLoader(lazy(() => import('screens/admin/Pages/pages')))

const Customize = ComponentLoader(
  lazy(() => import('screens/admin/Pages/customize'))
)
const PreviewPage = ComponentLoader(
  lazy(() => import('screens/admin/Pages/preview'))
)

// SETTINGS
const GeneralInfo = ComponentLoader(
  lazy(() => import('screens/admin/GeneralInfo/generalinfo'))
)

const Billings = ComponentLoader(
  lazy(() => import('screens/admin/Billings/billing'))
)
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const ApiKey = ComponentLoader(
  lazy(() => import('screens/admin/ApiKey/apikey'))
)
const Integration = ComponentLoader(
  lazy(() => import('screens/admin/Integration/integration'))
)
const IntegrateStripe = ComponentLoader(
  lazy(() => import('screens/public/stripe/stripe'))
)
const AgencyInfo = ComponentLoader(
  lazy(() => import('screens/admin/AgencyInfo/agencyinfo'))
)
const PageModule = ComponentLoader(
  lazy(() => import('screens/admin/Pages/index'))
)

const NewsFeed = ComponentLoader(lazy(() => import('screens/admin/Newsfeed')))

const AdminRoutes = () => {
  return [
    {
      path: '',
      element: <AdminLayout />,
      children: [
        // {
        //   path: "consumer",
        //   children: [
        //     {
        //       path: "",
        //       index: true,
        //       element: <Consumers />,
        //     },
        //     {
        //       path: "update",
        //       index: true,
        //       element: <UpdateBusiness />,
        //     },
        //   ],
        // },
        // {
        //   path: "Teams",
        //   element: <TeamsModule />,
        //   children: [
        //     {
        //       path: "",
        //       index: true,
        //       element: <Teams />,
        //     },
        //   ],
        // },
        {
          path: 'newsfeed',
          children: [
            {
              path: '',
              index: true,
              element: <NewsFeed />,
            },
            {
              path: 'update',
              index: true,
              element: <UpdateBusiness />,
            },
          ],
        },
        // {
        //   path: 'pages',
        //   element: <PageModule />,
        //   children: [
        //     {
        //       path: '',
        //       index: true,
        //       element: <Pages />,
        //     },
        //     {
        //       path: 'customize',
        //       index: true,
        //       element: <Customize />,
        //     },
        //     {
        //       path: 'preview-page',
        //       element: <PreviewPage />,
        //     },
        //   ],
        // },
        {
          path: 'integration',
          element: <IntegrateStripe />,
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: '',
          children: [
            {
              path: 'business-info',
              children: [
                {
                  path: '',
                  index: true,
                  element: <GeneralInfo />,
                },
                {
                  path: 'customer-details',
                  element: <CustomerDetails />,
                },
              ],
            },
            {
              path: 'billings',
              element: <Billings />,
            },
            {
              path: 'integration',
              element: <Integration />,
            },
            {
              path: 'agencyinfo',
              element: <AgencyInfo />,
            },
            {
              path: '',
              index: true,
              element: <Navigate replace to={'./generalinfo'} />,
            },
            {
              path: '*',
              element: <Navigate replace to={'./generalinfo'} />,
            },
          ],
        },

        {
          path: '',
          index: true,
          element: <Navigate replace to={'./business-info'} />,
        },
        {
          path: '*',
          index: true,
          element: <Navigate replace to={'./business-info'} />,
        },
      ],
    },
    {
      path: 'integrations',
      children: [
        {
          path: 'stripe',
          element: <IntegrateStripe />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate replace to={'./'} />,
    },
    {
      path: '*',
      element: <Navigate replace to={'./'} />,
    },
  ]
}

export default AdminRoutes
