import ProfileButton from 'component/Sidebar/profile-button'
import Sidebar from 'component/Sidebar/sidebar'
import TopHeader from 'component/TopHeader'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AdminLayout() {
  return (
    <>
      <div className='flex flex-col'>
        <TopHeader />
        <main className='p-4'>
          <Outlet />
        </main>
      </div>
    </>
  )
}

export default AdminLayout
